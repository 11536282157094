<template>
  <div>
    <component :is="dashboardComponent" />
  </div>
</template>

<script>
export default {
  name: 'MainDashboard',
  computed: {
    dashboardComponent() {
      const user = JSON.parse(localStorage.getItem('userData'))
      const rolesLevantamiento = ['ADMINISTRATOR', 'INVITADO', 'BODEGA', 'LEVANTAMIENTO', 'MIGRACION']

      if (rolesLevantamiento.includes(user.role)) {
        return () => import('@/views/dashboard/Crecimiento.vue').then(m => m.default)
        // return () => import('@/components/maps/MapLevantamiento.vue').then(m => m.default)
      }
      return () => import('@/views/dashboard/Monitoreo.vue').then(m => m.default)
    },
  },
}
</script>
